module.exports = [{
      plugin: require('../../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"extensions":[".mdx",".md"],"gatsbyRemarkPlugins":[{"resolve":"/vercel/path0/node_modules/gatsby-remark-images","id":"ade81373-2e17-5aa1-b5f3-157fb1a3761c","name":"gatsby-remark-images","version":"7.7.0","modulePath":"/vercel/path0/node_modules/gatsby-remark-images/index.js","pluginOptions":{"plugins":[],"maxWidth":1140,"quality":50,"showCaptions":true,"linkImagesToOriginal":false,"disableBgImageOnAlpha":true,"markdownCaptions":false,"backgroundColor":"white","withWebp":false,"withAvif":false,"loading":"lazy","decoding":"async","disableBgImage":false},"nodeAPIs":["pluginOptionsSchema"],"browserAPIs":["onRouteUpdate"],"ssrAPIs":[]},{"resolve":"/vercel/path0/node_modules/gatsby-remark-embed-video","id":"f1dc350d-b65e-5e5d-a9ea-68729644d699","name":"gatsby-remark-embed-video","version":"3.2.1","modulePath":"/vercel/path0/node_modules/gatsby-remark-embed-video/index.js","pluginOptions":{"plugins":[],"width":800},"nodeAPIs":[],"browserAPIs":[],"ssrAPIs":[]},{"resolve":"/vercel/path0/node_modules/gatsby-remark-responsive-iframe","id":"14fa90d3-ae84-54a3-bb14-8c56bdcaa86e","name":"gatsby-remark-responsive-iframe","version":"6.7.0","modulePath":"/vercel/path0/node_modules/gatsby-remark-responsive-iframe/index.js","pluginOptions":{"plugins":[]},"nodeAPIs":[],"browserAPIs":[],"ssrAPIs":[]},{"resolve":"/vercel/path0/node_modules/gatsby-remark-copy-linked-files","id":"cf104666-f93f-5e9d-9c2e-1bd9003e1e14","name":"gatsby-remark-copy-linked-files","version":"6.7.0","modulePath":"/vercel/path0/node_modules/gatsby-remark-copy-linked-files/index.js","pluginOptions":{"plugins":[]},"nodeAPIs":[],"browserAPIs":[],"ssrAPIs":[]},{"resolve":"/vercel/path0/node_modules/gatsby-remark-smartypants","id":"d48194e5-b344-5f22-b93d-76045ee44e88","name":"gatsby-remark-smartypants","version":"6.7.0","modulePath":"/vercel/path0/node_modules/gatsby-remark-smartypants/index.js","pluginOptions":{"plugins":[]},"nodeAPIs":[],"browserAPIs":[],"ssrAPIs":[]}],"mdxOptions":{}},
    },{
      plugin: require('../../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":1140,"quality":50,"showCaptions":true,"linkImagesToOriginal":false,"disableBgImageOnAlpha":true,"markdownCaptions":false,"backgroundColor":"white","withWebp":false,"withAvif":false,"loading":"lazy","decoding":"async","disableBgImage":false},
    },{
      plugin: require('../../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../packages/blog/gatsby-blog-core/gatsby-browser.js'),
      options: {"plugins":[],"paginatePostsPage":true,"homePostsPerPage":6,"siteUrl":"https://www.ggn.dev","services":{"graphComment":true,"mailchimp":false,"algolia":true,"disqus":false},"sources":{"local":true,"contentful":false,"sanity":false,"strapi":false},"localPaths":{"assets":"content/assets","posts":"content/posts","author":"content/authors","category":"content/categories"},"basePath":"/","sitePaths":{"category":"/category","author":"/author","tag":"/tag"},"postsPerPage":6,"collectionPostsPerPage":6,"darkMode":true,"includeExcerpt":true,"includeTimeToRead":true,"includeTableOfContents":true,"imageQuality":100,"slugSanitizeRegex":"/[^ws.{}/-]+/g"},
    },{
      plugin: require('../../node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"google":{"families":["Inter:400,600,700,900&display=swap"]}},
    },{
      plugin: require('../../../../var/task/node_modules/@vercel/gatsby-plugin-vercel-analytics/gatsby-browser.js'),
      options: {"plugins":[],"debug":false},
    },{
      plugin: require('../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Greg Goodwin Software Engineer","short_name":"Greg Goodwin","start_url":"/","background_color":"#ffffff","theme_color":"#5a67d8","display":"minimal-ui","icon":"content/assets/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"6a5213fac9ebf4b096ef01122c61bdb7"},
    },{
      plugin: require('../../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"G-MXY1HXLQGQ","head":false,"anonymize":false,"respectDNT":false,"exclude":[],"pageTransitionDelay":0,"enableWebVitalsTracking":false},
    },{
      plugin: require('../../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-NJ3D7B7","includeInDevelopment":false,"defaultDataLayer":{"type":"object","value":{"platform":"gatsby"}},"enableWebVitalsTracking":true,"routeChangeEventName":"gatsby-route-change","selfHostedOrigin":"https://www.googletagmanager.com"},
    },{
      plugin: require('../../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
